import "../../../../styles/customers/About.css";
import logo from "../../../../assets/images/bigsack-logo.jpg";
import { IoMdClose } from "react-icons/io";
import { CustomHeader } from "../../../../helpers/seo/CustomHead";
import { SEOMETA } from "../../../../helpers/seo/seo.config";
export default function AboutUs({ closeForm, modalEvent }) {
  return (
    <>
      <CustomHeader
        title={SEOMETA?.aboutPageMeta?.title}
        metaContent={SEOMETA?.aboutPageMeta?.metaContent}
      ></CustomHeader>
      <div className="about-us-container">
        <span className="about-header-sup">
          <img src={logo} alt="BigSack" />
          <button onClick={closeForm}>
            <IoMdClose size={25} className="close-icon" />
          </button>
        </span>
        <span className="about-header">
          <h3>About BigSack</h3>
        </span>
        <div className="about-body">
          <p>
            Big Sack is an E-commerce business that provides home delivery
            service for a wide range of groceries particularly fruits,
            vegetables and meat.
          </p>
          <p>
            We are aware of the hassle shoppers experience in their regular
            commute to the market in heavy traffic. Our goal is to relieve our
            customers of the stressful shopping experience.
          </p>
          <p>
            We are mindful of shoppers’ convenience and our aim is to help them
            save time in a bustling economy where time is of the essence.
          </p>
        </div>
      </div>
    </>
  );
}
