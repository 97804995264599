//Modal imports
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import logo from "../../../../assets/images/big-sack-logo-abbrev-white-interlay.svg";
import { ButtonLoader } from "../PreLoader";

export default function ProcessingCartItem({
  showProcessingCart,
  displayCart,
  error,
  success,
  cartUpdating,
  cartDeleting,
}) {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showProcessingCart}
        // onClose={showViewInvoice}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showProcessingCart}>
          <Box sx={ModalStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem 1rem",
                rowGap: "1rem",
                textAlign: "center",
              }}
            >
              {" "}
              <img src={logo} alt="logo" className="logo" />
              {cartUpdating && (
                <span
                  style={{
                    fontWeight: "600",
                    fontStyle: "italic",
                    textAlign: "center",
                    color: "purple",
                  }}
                >
                  Adding item to cart to your cart...
                </span>
              )}
              {cartDeleting && (
                <span
                  style={{
                    fontWeight: "600",
                    fontStyle: "italic",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Removing item from your cart...
                </span>
              )}
              {(cartUpdating || cartDeleting) && <ButtonLoader />}
              {error && (
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "0.95rem",
                    fontStyle: "italic",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  An error occured while updating your cart. Please try again
                  later.
                </span>
              )}
              {cartUpdating || cartDeleting
                ? null
                : success && (
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "0.95rem",
                        fontStyle: "italic",
                        color: "green",
                      }}
                    >
                      Your cart was updated successfully...
                    </span>
                  )}
              <button
                type="button"
                style={{
                  width: "50%",
                  height: "max-ontent",
                  padding: "0.6rem 1rem",
                  backgroundColor: "#000",
                  color: "#fff",
                  textAlign: "center",
                  borderRadius: "1rem",
                }}
                disabled={cartUpdating || cartDeleting}
                onClick={displayCart}
              >
                {" "}
                Close
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

//Modal styles
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  maxHeight: "85vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: `var(--rounded-border-radius)`,
  p: 4,
};
