import "../../../../styles/customers/Terms.css";
import logo from "../../../../assets/images/bigsack-logo.jpg";
import { IoMdClose } from "react-icons/io";
import CustomerLayout from "../../../globals/customers/Layout";
export default function TermsandConditions({ closeForm, modalEvent }) {
  return (
    <CustomerLayout>
      <div className="terms-container">
        <span className="terms-header">
          <h2>Terms and Conditions </h2>
        </span>
        <div className="terms-body">
          <p>
            Big Sack Limited[BSL] is the licensed owner of the brand
            bigsackgh.com and the website bigsackgh.com (”The Site). As a
            visitor to the Site/ Customer you are advised to please read the
            Term & Conditions carefully. By accessing the services provided by
            the Site you agree to the terms provided in this Terms & Conditions
            document.
          </p>
          <h4>Personal Information </h4>
          <p>
            As part of the registration process on the Site, BSL may collect the
            following personal identifiable information about you: Name 
            including first and last name, alternate email address, mobile phone
            number, Postal code and information about the pages on the site you 
            visit/access, the links you click on the site, the number of times you 
            access the page and any such browsing information.
            
          </p>
          <h4>License & Site Access</h4>
          <p>  
            BSL grants you a limited sub-license to access and make personal use 
            of this site and not to download (other than page caching) or modify 
            it, or any portion of it, except with express written consent of 
            BSL. This license does not include any resale or commercial use of 
            this site or its contents; any collection and use of any product 
            listings, descriptions, or prices; any derivative use of this site 
            or its contents; any downloading or copying of account information for
            the benefit of another merchant; or any use of data mining, robots, or 
            similar data gathering and extraction tools. This site or any portion 
            of this site may not be reproduced, duplicated, copied, sold, resold, 
            visited, or otherwise exploited for any commercial purpose without 
            express written consent of BSL. You may not frame or utilize framing 
            techniques to enclose any trademark, logo, or other proprietary 
            information (including images, text, page layout, or form) of the Site 
            or of BSL and its affiliates without express written consent. 
            You may not use any meta tags or any other "hidden text" utilizing 
            the Site’s or BSL’s name or trademarks without the express written 
            consent of BSL. Any unauthorized use terminates the permission or license
            granted by BSL
            
          </p>
          <h4>Account & Registration Obligations </h4>
          <p>
            All shoppers have to register and login for placing orders on the
            Site. You have to keep your account and registration details current
            and correct for communications related to your purchases from the
            site. By agreeing to the terms and conditions, the shopper agrees to
            receive promotional communication and newsletters upon registration.
            The customer can opt out either by unsubscribing in "My Account" or
            by contacting the customer service.
          </p>
          <h4>Pricing </h4>
          <p>
            All the products listed on the Site will be sold at MRP unless 
            otherwise specified. The pricesmentioned at the time of ordering 
            will be the prices charged on the date of the delivery.Although 
            prices of most of the products do not fluctuate on a daily basis but 
            some of the commodities and fresh food prices do change on a daily 
            basis. In case the prices are higher or lower on the date of delivery
            no additional charges will be collected or refunded as the 
            case may be at the time of the delivery of the order.
          </p>
          <h4>Cancellation by Site / Customer </h4>
          <p>
            You as a customer can cancel your order six hours up to the delivery 
            time for which you have placed an order by calling our customer service. 
            In such a case we will refund any payments already made by you for the 
            order. If we suspect any fraudulent transaction by any customer or any 
            transaction which defies the terms & conditions of using the website,
            we at our sole discretion could cancel such orders. We will maintain a 
            negative list of all fraudulent transactions and customers and would 
            deny access to them or cancel any orders placed by them.
          </p>
          <h4>Return & Refunds </h4>
          <p>
            We have a "no questions asked return and refund policy" which
            entitles all our members to return the product at the time of
            delivery if due to some reason they are not satisfied with the
            quality or freshness of the product. We will take the returned
            product back with us and credit their Mobile Money(MoMo) Account for
            the value of the return products.
          </p>
          <h4>You Agree and Confirm </h4>
          <p>
            1. That in the event that a non-delivery occurs on account of a
            mistake by you (i.e. wrong name or address or any other wrong
            information) any extra cost incurred by BSL for redelivery shall be
            claimed from you.
          </p>{" "}
          <p>
            2. That you will use the services provided by the Site, its
            affiliates, consultants and contracted companies, for lawful
            purposes only and comply with all applicable laws and regulations
            while using and transacting on the Site.{" "}
          </p>
          <p>
            3. You will provide authentic and true information in all instances
            where such information is requested of you. BSL reserves the right
            to confirm and validate the information and other details provided
            by you at any point of time. If upon confirmation your details are
            found not to be true (wholly or partly), it has the right in its
            sole discretion to reject the registration and debar you from using
            the Services and / or other affiliated websites without prior
            intimation whatsoever.{" "}
          </p>
          <p>
            4. You authorize Big Sack to contact you for any transactional
            purposes related to your order/account.{" "}
          </p>
          <p>
            5. That the address at which delivery of the product ordered by you is
            to be made will be correct and proper in all respects.{" "}
          </p>
          <p>
            6. That before placing an order you will check the product
            description carefully. By placing an order for a product you agree
            to be bound by the conditions of sale included in the item's
            description.
          </p>
          <h4>You may not use the Site for any of the following purposes: </h4>
          <p>
            1. Disseminating any unlawful, harassing, libelous, abusive,
            threatening, harmful, vulgar, obscene, or otherwise objectionable
            material.
          </p>{" "}
          <p>
            2. Transmitting material that encourages conduct that constitutes a
            criminal offence or results in civil liability or otherwise breaches
            any relevant laws, regulations or code of practice.
          </p>
          <p>3. Gaining unauthorized access to other computer systems.</p>
          <p>
            4. Interfering with any other person's use or enjoyment of the Site.{" "}
          </p>
          <p>5. Breaching any applicable laws;</p>
          <p>
            6. Interfering or disrupting networks or web sites connected to the
            Site.{" "}
          </p>
          <p>
            7. Making, transmitting or storing electronic copies of materials
            protected by copyright without the permission of the owner.{" "}
          </p>
          <h4>Colours </h4>
          <p>
            We have made every effort to display the colours of our products
            that appear on the Website as accurately as possible. However, as
            the actual colours you see will depend on your monitor, we cannot
            guarantee that your monitor's display of any colour will be
            accurate.
          </p>
          <h4>Modification of Terms & Conditions of Service </h4>
          <p>
            BSL may at any time modify the Terms & Conditions of Use of the
            Website without any prior notification to you. You can access the
            latest version of these Terms & Conditions at any given time on the
            Site. You should regularly review the Terms & Conditions on the
            Site. In the event the modified Terms & Conditions is not acceptable
            to you, you should discontinue using the Service. However, if you
            continue to use the Service you shall be deemed to have agreed to
            accept and abide by the modified Terms & Conditions of Use of this
            Site.
          </p>
          <h4>Governing Law and Jurisdiction </h4>
          <p>
            This User Agreement shall be construed in accordance with the
            applicable laws of Ghana. The Courts at Ghana shall have exclusive
            jurisdiction in any proceedings arising out of this agreement. Any
            dispute or difference either in interpretation or otherwise, of any
            terms of this User Agreement between the parties hereto, the same
            shall be referred to an independent arbitrator who will be appointed
            by BSL and his decision shall be final and binding on the parties
            hereto. The above arbitration shall be in accordance with the
            Alternate Dispute Resolution Act 2010 (Act 798) as amended from time
            to time. The arbitration shall be held in Accra. The High Court of
            judicature at Accra alone shall have the jurisdiction and the Laws
            of Ghana shall apply.
          </p>
          <h4>Copyright & Trademark </h4>
          <p>
            BSL, its suppliers and licensors expressly reserve all intellectual 
            property rights in all text, programs, products, processes, technology, 
            content and other materials, which appear on this Site. Access to this
            Website does not confer and shall not be considered as conferring upon 
            anyone any license under any of BSL or any third party&#39;s intellectual 
            property rights. All rights, including copyright, in this website are 
            owned by or licensed to BSL. Any use of this website or its contents, 
            including copying or storing it or them in whole or part, other than for 
            your own personal, non-commercial use is prohibited without the permission of
            BSL. You may not modify, distribute or re-post anything on this website for 
            any purpose. The names and logos and all related product and service names, 
            design marks and slogans are the trademarks or service marks of BSL, 
            its affiliates, its partners or its suppliers. All other marks are the property
            of their respective companies. No trademark or service mark license is granted
            in connection with the materials contained on this Site. Access to this Site
            does not authorize anyone to use any name, logo or mark in any manner.
            References on this Site to any names, marks, products or services of third 
            parties or hypertext links to third party sites or information are provided
            solely as a convenience to you and do not in any way constitute or imply BSL 
            endorsement, sponsorship or recommendation of the third party, information, 
            product or service. BSL is not responsible for the content of any third
            party sites and does not make any representations regarding the content or
            accuracy of material on such sites. If you decide to link to any such third party
            websites, you do so entirely at your own risk. All materials, including images, 
            text, illustrations, designs, icons, and written and other materials that are part 
            of this Website (collectively, the "Contents") are intended solely for personal,
            non-commercial use. You may download or copy the Contents and other downloadable
            materials displayed on the Website for your personal use only. No right, title or
            interest in any downloaded materials or software is transferred to you as a result 
            of any such downloading or copying. You may not reproduce (except as noted above),
            publish, transmit, distribute, display, modify, create derivative works from,
            sell or participate in any sale of or exploit in any way, in whole or in part, any 
            of the Contents, the Website or any related software. All software used on this Website
            is the property of BSL or its licensees and suppliers and protected by Ghana and 
            international copyright laws. The Contents and software on this Website may be 
            used only as a shopping resource. Any other use, including the reproduction,
            modification, distribution, transmission, republication, display, or performance, of 
            the Contents on this Website is strictly prohibited. Unless otherwise noted, all Contents
            are copyrights, trademarks, trade dress and/or other intellectual property owned, 
            controlled or licensed by BSL, one of its affiliates or by third parties who have 
            licensed their materials to BSL and are protected by Ghana and international copyright laws. 
            The compilation (meaning the collection, arrangement, and assembly) of all Contents on 
            this Website is the exclusive property of BSL and is also protected by Ghana and 
            international copyright laws.
          </p>
        </div>
      </div>
    </CustomerLayout>
  );
}
