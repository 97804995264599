import { BiLogOut } from "react-icons/bi";
import { FaHistory, FaUser } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import {
  MdOutlineMiscellaneousServices,
  MdOutlineSecurity,
} from "react-icons/md";
import "../../../../../styles/customers/AccountManagement.css";

export const ProfileModal = ({
  viewProfileOptions,
  loggedInUser,
  modalEvent,
}) => {
  return (
    <div className="profile-modal">
      {" "}
      <div className="close-container">
        <span></span>
        <button className="modal-close-btn">
          <IoMdCloseCircle
            size="25"
            className="icon"
            onClick={viewProfileOptions}
          />
        </button>
      </div>
      <div className="modal-basic-profile">
        {loggedInUser?.image_url !== undefined ? (
          <img
            src={loggedInUser?.image_url}
            alt="user"
            className="modal-profile-avatar"
          />
        ) : (
          <button type="button" className="modal-profile-avatar-avatar">
            {loggedInUser?.firstName?.charAt(0)}&nbsp;
            {loggedInUser?.lastName?.charAt(0)}
          </button>
        )}
        <span className="modal-profile-text">
          <h4>
            {loggedInUser?.firstName}&nbsp;
            {loggedInUser?.lastName}
          </h4>
          <a href={`${loggedInUser?.email}`}>{loggedInUser?.email}</a>
        </span>
      </div>
      <div className="modal-account-links">
        <a className="link" href="/account-management">
          <FaUser className="icon" size="20" /> <span>Account</span>
        </a>
        <a className="link" href="/account-management">
          <MdOutlineSecurity className="icon" size="20" />
          <span>Security</span>
        </a>

        <a className="link" href="/account-management">
          <MdOutlineMiscellaneousServices className="icon" size="20" />
          <span>Others</span>
        </a>
        <a className="link" href="/order-history">
          <FaHistory className="icon" size="20" />
          <span>Order History</span>
        </a>
      </div>
      <button
        className="modal-profile-logout"
        onClick={modalEvent?.displayLogout}
      >
        <BiLogOut className="icon" size="20" />
        <span>Log Out</span>
      </button>
    </div>
  );
};
