import {
  AiOutlineShop,
  AiOutlineUserAdd,
  AiOutlineUserSwitch,
} from "react-icons/ai";
import { BiCategoryAlt, BiLogOut } from "react-icons/bi";
import { FaArrowCircleRight, FaHistory } from "react-icons/fa";
import { FiHeart, FiShoppingCart } from "react-icons/fi";
import "../../../../styles/customers/AccountManagement.css";
import { useState } from "react";
export default function MenuDrawer({
  showCustomerLogin,
  showCustomerRegistration,
  wishlistItems,
  remoteCartData,
  loggedInUser,
  modalEvent,
}) {
  //set user data to state
  const [user, setUser] = useState(loggedInUser);
  return (
    <div className="mobile-drawer">
      {user !== undefined ? (
        <a className="profile" href="/account-management">
          {user?.image_url && (
            <img
              src={user?.image_url}
              alt="user"
              className="modal-profile-avatar"
            />
          )}
          {!user?.image_url && (
            <div className="modal-avatar-placeholder">
              {user?.firstName.charAt(0)}&nbsp;
              {user?.lastName.charAt(0)}
            </div>
          )}
          <span>
            {user?.firstName}&nbsp;{user?.lastName} &nbsp;
            <FaArrowCircleRight size="20" className="icon" />
          </span>
        </a>
      ) : null}
      <div className="drawer-navigations">
        {user === undefined ? (
          <>
            <button className="link" id="login-ac" onClick={showCustomerLogin}>
              <AiOutlineUserSwitch size="20" className="icon" />
              &nbsp; &nbsp;
              <span>Login</span>
            </button>
            <button
              className="link"
              id="register-ac"
              onClick={showCustomerRegistration}
            >
              <AiOutlineUserAdd size="20" className="icon" />
              &nbsp; &nbsp;
              <span>Register</span>
            </button>
          </>
        ) : null}
        <a className="link" href="/product-categories">
          <BiCategoryAlt size="20" className="icon" />
          &nbsp; &nbsp;
          <span>Categories</span>
        </a>
        <a className="link" href="/shop">
          <AiOutlineShop size="20" className="icon" />
          &nbsp; &nbsp;
          <span>Shop</span>
        </a>

        <a className="link" href="/wishlist">
          <FiHeart size="20" className="icon" />
          &nbsp; &nbsp;Wishlist&nbsp;
          {wishlistItems !== undefined && wishlistItems?.length > 0 ? (
            <sup className="notification-badge">{wishlistItems?.length}</sup>
          ) : null}
        </a>
        {user !== undefined ? (
          <a className="link" href="/cart">
            <FiShoppingCart size="20" className="icon" />
            &nbsp; &nbsp;Cart&nbsp;
            {remoteCartData?.items !== undefined &&
            remoteCartData?.items?.length > 0 ? (
              <sup className="notification-badge">
                {remoteCartData?.items?.length}
              </sup>
            ) : null}
          </a>
        ) : (
          <button onClick={showCustomerLogin} className="link">
            <FiShoppingCart size="20" className="icon" />
            &nbsp; &nbsp;Cart&nbsp;
            {remoteCartData?.items !== undefined &&
            remoteCartData?.items?.length > 0 ? (
              <sup className="notification-badge">
                {remoteCartData?.items?.length}
              </sup>
            ) : null}
          </button>
        )}
        {user !== undefined ? (
          <>
            <a
              className="link"
              href="/order-history"
              style={{
                borderBottom: ` 1px solid var(--global-gray-4)`,
                paddingBottom: "0.5rem",
              }}
            >
              <FaHistory className="icon" size="20" />
              &nbsp; &nbsp;Order History
            </a>
            <button
              className="link"
              href="#"
              onClick={modalEvent?.displayLogout}
            >
              <BiLogOut className="icon" size="20" />
              &nbsp; &nbsp;Logout
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}
