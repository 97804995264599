import React from "react";
import { Helmet } from "react-helmet";

export const CustomHeader = ({ title, metaContent }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name={"description"} content={metaContent} />
    </Helmet>
  );
};
