import { useState } from "react";
import { LoaderIcon, Toaster, toast } from "react-hot-toast";
import secondaryLogo from "../../../assets/images/bigsack-logo.jpg";
import { about, shopping, socialConnect } from "../../../data/footerLinks";
import { GetallCustomerDeliveryLocations } from "../../../hooks/customerShopData";

//Modal imports
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import { useEffect } from "react";
import CustomerLogin from "../../auth/customers/Login";
import CustomerRegister from "../../auth/customers/Register";
import AboutUs from "../../customers/page-components/AboutCompany/AboutBigsack";

export default function Footer() {
  //component states
  const [showLogin, setShowCustomerLogin] = useState(false);
  const [showRegisterCustomer, setShowRegisterCustomer] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  //customer login event trigger
  const showCustomerLogin = () => {
    setShowCustomerLogin(!showLogin);
  };

  //display Login form on sign up closure
  const displayLogin = () => {
    setShowCustomerLogin(true);
    setShowRegisterCustomer(false);
    setShowAbout(false);
  };

  //customer registration event trigger
  const showCustomerRegistration = () => {
    setShowRegisterCustomer(!showRegisterCustomer);
  };

  //display registration form on login closure
  const displayRegistration = () => {
    setShowCustomerLogin(false);
    setShowRegisterCustomer(true);
    setShowAbout(false);
  };

  const showcustomerAbout = () => {
    setShowAbout(!showAbout);
  };

  //display bout company modal
  const displayAbout = () => {
    setShowCustomerLogin(false);
    setShowRegisterCustomer(false);
    setShowAbout(true);
  };

  //Registration notif
  const RegistrationSuccess = () =>
    toast(
      `Registration successful. Please check your email for verification instructions.`,
      {
        position: "top-right",
        // Custom Icon
        icon: "✅",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      }
    );
  //Login notif
  const LoginSuccess = () =>
    toast(`Login succecssful. Have fun shopping!`, {
      position: "top-right",
      // Custom Icon
      icon: "✅",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
    });

  //auth modal events
  const modalEvent = {
    displayLogin,
    displayRegistration,
  };

  //mobile responsive screen
  const [screenWidthMobile, setScreenWidthMobile] = useState(
    window.matchMedia("(min-width: 320px) and (max-width: 420px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 320px) and (max-width: 420px)")
      .addEventListener("change", (e) => setScreenWidthMobile(e.matches));
  }, []);

  const onError = (error) => {
    console.log("fetch error", error);
  };

  const onSuccess = (data) => {
    // console.log("sucesss :", data);
  };

  //get locales request handler
  const {
    data: all_locales,
    isLoading,
    isError,
    // error,
    isSuccess,
  } = GetallCustomerDeliveryLocations(onSuccess, onError);
  //get current date
  var baseYear = new Date().toISOString().split("T")[0].split("-")[0];

  const deliveryLocations = all_locales?.data;
  return (
    <>
      {" "}
      <Toaster reverseOrder={false} />
      <div className="footer">
        <div className="footer-top">
          <img
            src={secondaryLogo}
            alt="secondary-logo"
            className="secondary-logo"
          />
          <div className="footer-navigations">
            <div className="footer-sections accounts">
              <h4>Accounts</h4>
              <div className="footer-links">
                {/* {accounts.map((item, index) => ( */}
                <button className="footer-link" onClick={showCustomerLogin}>
                  Login
                </button>
                <button
                  className="footer-link"
                  onClick={showCustomerRegistration}
                >
                  Register
                </button>
                <a
                  href="/bigsack/customer/forgot-password"
                  className="footer-link"
                >
                  Forgot password
                </a>
                {/* ))} */}
              </div>
            </div>

            <div className="footer-sections shopping">
              <h4>Shopping</h4>
              <div className="footer-links">
                {shopping.map((item, index) => (
                  <a key={index + 1} href={item?.link} className="footer-link">
                    {item?.title}
                  </a>
                ))}
              </div>
            </div>

            <div className="footer-sections about">
              <h4>About</h4>
              <div className="footer-links">
                <a onClick={displayAbout} href={"#"} className="footer-link">
                  About BigSack
                </a>
                {about.map((item, index) => (
                  <a key={index + 1} href={item?.link} className="footer-link">
                    {item?.title}
                  </a>
                ))}
              </div>
            </div>

            <div className="footer-sections connect">
              <h4>Connect with us</h4>
              <div className="footer-links">
                {socialConnect.map((item, index) => (
                  <a
                    key={index + 1}
                    href={item?.link}
                    title={`${item?.title}-${item?.link}`}
                    className="footer-link"
                  >
                    {item?.icon} &nbsp;
                    {item?.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-delivery-locale">
          <h4>Areas we serve</h4>
          {isLoading ? (
            <>
              Getting location updates... <LoaderIcon />
            </>
          ) : isSuccess && deliveryLocations?.length > 0 ? (
            <div className="footer-links">
              {deliveryLocations?.map((locale, index) => (
                <span key={index + 1} className="footer-link">
                  {locale?.area?.length > 30
                    ? `${locale?.area.slice(0, 29)}...`
                    : `${locale?.area}`}
                </span>
              ))}
            </div>
          ) : (isSuccess && deliveryLocations?.length === 0) || isError ? (
            <span style={{ fontSize: "1rem", fontStyle: "italic" }}>
              We are reviewing our delivery zones and will update this section
              soon...{" "}
            </span>
          ) : (
            <span style={{ fontSize: "1rem", fontStyle: "italic" }}>
              We are reviewing our delivery zones and will update this section
              soon...{" "}
            </span>
          )}
        </div>
        <span className="copyright">
          &copy; &nbsp; {baseYear} &nbsp;|&nbsp; BigSack
        </span>
      </div>
      {/* Login Modal */}
      {showLogin && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showLogin}
            // onClose={showCustomerLogin}showCustomerLogin
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showLogin}>
              <Box
                sx={screenWidthMobile ? ModalStyleMobile : ModalStyleDesktop}
              >
                <CustomerLogin
                  closeForm={showCustomerLogin}
                  modalEvent={modalEvent}
                  LoginSuccess={LoginSuccess}
                />
              </Box>
            </Fade>
          </Modal>
        </>
      )}
      {/* Registration Modal */}
      {showRegisterCustomer && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showRegisterCustomer}
            // onClose={showViewInvoice}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showRegisterCustomer}>
              <Box
                sx={screenWidthMobile ? ModalStyleMobile : ModalStyleDesktop}
              >
                <CustomerRegister
                  closeForm={showCustomerRegistration}
                  modalEvent={modalEvent}
                  RegistrationSuccess={RegistrationSuccess}
                />
              </Box>
            </Fade>
          </Modal>
        </>
      )}
      {/* Registration Modal */}
      {showAbout && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showAbout}
            // onClose={showAbout}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showAbout}>
              <Box
                sx={screenWidthMobile ? ModalStyleMobile : ModalStyleDesktop}
              >
                <AboutUs
                  closeForm={showcustomerAbout}
                  modalEvent={modalEvent}
                  RegistrationSuccess={RegistrationSuccess}
                />
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </>
  );
}
//Modal styles
const ModalStyleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: `var(--border-radius-2)`,
  padding: 4,
  maxHeight: "85vh",
  overflowY: "auto",
};

const ModalStyleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "32.5%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: `var(--border-radius-2)`,
  padding: "1rem 1.5rem",
};
