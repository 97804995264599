import React, { useEffect, useState } from "react";

import mobileLogo from "../../../../assets/images/big-sack-logo-secondary.png";
import logo from "../../../../assets/images/bigsack-logo.jpg";

import {
  AiOutlineShop,
  AiOutlineUserAdd,
  AiOutlineUserSwitch,
} from "react-icons/ai";
import { BiCategoryAlt } from "react-icons/bi";
import { FiHeart, FiShoppingCart } from "react-icons/fi";
import { ProductsSearch } from "../../ui/SearchBar";

//Modal imports
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { FaCaretDown } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { RiMenu4Fill } from "react-icons/ri";
import Cookies from "universal-cookie";
import { ENDPOINTS } from "../../../../constants/endpoints";
import { CartState } from "../../../../helpers/store/ContextStore/cart/StoreProvider";
import { GetUserCart } from "../../../../hooks/customerShopData";
import CustomerLogin from "../../../auth/customers/Login";
import UserLogout from "../../../auth/customers/Logout";
import CustomerRegister from "../../../auth/customers/Register";
import { ProfileModal } from "../../../customers/page-components/AccountManagement/subs/ProfileModal";
import { CartModal } from "../../../customers/page-components/Orders/CustomerCartModal";
import MenuDrawer from "./MobileMenuDrawer";
import { SearchResults } from "./searchResults";
import { WishListState } from "../../../../helpers/store/ContextStore/cart/wishListProvider";
const cookies = new Cookies();
var baseURL = process.env.REACT_APP_API_URL;

export default function SubHeaderComponent({ loggedInUser }) {
  /*GET CART AND WISHLIST DATA*/
  //get wishlist data
  //wishlist
  const { wishlist, setWishList } = WishListState();

  const wishListState = {
    wishlist:
      typeof window !== "undefined"
        ? localStorage.getItem("__user_wishlist")
        : [],
  };

  const [wishlistItems, setWishListItems] = useState(
    JSON.parse(wishListState?.wishlist)
  );

  useEffect(() => {
    setWishListItems(JSON.parse(wishListState?.wishlist));
    setWishList(JSON.parse(wishListState?.wishlist));
  }, [wishListState?.wishlist]);

  //get cart data
  const cart = window.localStorage.getItem("__user_cart");
  var parsedCart = JSON.parse(cart);

  //component states
  const [carttItems, setCartItems] = useState([]);
  const [showLogin, setShowCustomerLogin] = useState(false);
  const [showRegisterCustomer, setShowRegisterCustomer] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showCustomerCart, setShowCustomerCart] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [showLogoutCustomer, setShowLogoutCustomer] = useState(false);

  //import cart context states
  const {
    state: { userCart },
    dispatch,
  } = CartState();

  //============## search related logic ##================//
  //get access token
  const ACCESS_TOKEN = cookies.get("LOG_BS_AC_TK");
  //states
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);

  const productsSearchRequest = async () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    };
    await axios
      .get(baseURL + ENDPOINTS.products + `?search=${searchInput}`, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          setSearchResults(response?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const showProductSearch = () => {
    setShowSearchResults(!showSearchResults);
  };

  const handleSearchInputChange = (e) => {
    setShowSearchResults(true);
    e.preventDefault();
    setSearchInput(e.target.value);
    const count = searchInput.length;
    if (count === 0) {
      setSearching(false);
      return;
    }
    if (count >= 2) {
      productsSearchRequest();
      setSearching(true);
    }
  };

  //============## end of search related logic==================//
  //customer login event trigger
  const showCustomerLogin = () => {
    setShowCustomerLogin(!showLogin);
  };

  //display Login form on sign up closure
  const displayLogin = () => {
    setShowCustomerLogin(true);
    setShowRegisterCustomer(false);
  };

  //customer registration event trigger
  const showCustomerRegistration = () => {
    setShowRegisterCustomer(!showRegisterCustomer);
  };

  //display registration form on login closure
  const displayRegistration = () => {
    setShowCustomerLogin(false);
    setShowRegisterCustomer(true);
  };

  //customer logout event trigger
  const showCustomerLogout = () => {
    setShowLogoutCustomer(!showLogoutCustomer);
  };

  //display registration form on login closure
  const displayLogout = () => {
    setShowCustomerLogin(false);
    setShowRegisterCustomer(false);
    setShowLogoutCustomer(true);
  };

  //auth modal events
  const modalEvent = {
    displayLogin,
    displayRegistration,
    displayLogout,
  };

  //trigger event for cart  modal
  const viewCart = () => {
    setShowCustomerCart(!showCustomerCart);
    setShowProfileDropdown(false);
  };

  //trigger event for user profile dropdown
  const viewProfileOptions = () => {
    setShowProfileDropdown(!showProfileDropdown);
    setShowCustomerCart(false);
  };

  //trigger event for user mobile drawer
  const viewMobileDrawer = () => {
    setShowMobileDrawer(!showMobileDrawer);
  };

  //Registration notif
  const RegistrationSuccess = () =>
    toast(
      `Registration successful. Please check your email for verification instructions.`,
      {
        position: "top-right",
        // Custom Icon
        icon: "✅",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      }
    );
  //Login notif
  const LoginSuccess = () =>
    toast(`Login succecssful. Have fun shopping!`, {
      position: "top-right",
      // Custom Icon
      icon: "✅",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
    });

  //Login notif
  const LogoutSuccess = () =>
    toast(
      `Logged out successfully. Do login again to perform transactions ☺️`,
      {
        position: "top-right",
        // Custom Icon
        icon: "✅",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      }
    );

  //mobile responsive screen
  const [screenWidthMobile, setScreenWidthMobile] = useState(
    window.matchMedia("(min-width: 320px) and (max-width: 420px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 320px) and (max-width: 420px)")
      .addEventListener("change", (e) => setScreenWidthMobile(e.matches));
    setWishListItems(wishlist);
    setCartItems(parsedCart);
  }, []);

  //Change header background on scroll
  const [headerbackgroundChange, setHeaderbackgroundChange] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setHeaderbackgroundChange(true);
    } else {
      setHeaderbackgroundChange(false);
    }
  };
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  //on component mount or update
  useEffect(() => {}, []);

  const onSuccess = (cart) => {
    // console.log("cartData", cart?.data);
  };
  const onError = (error) => {
    // console.log("error :", error);
  };

  //get remote cart
  const {
    data: remoteCart,
    isLoading,
    isError,
    error,
    isSuccess,
  } = GetUserCart(onSuccess, onError);
  const remoteCartData = remoteCart?.data;

  return (
    <>
      <Toaster reverseOrder={false} />
      <div
        className={
          screenWidthMobile && headerbackgroundChange
            ? "sub-header-scroll"
            : "sub-header"
        }
      >
        <a href="/">
          <img
            src={screenWidthMobile ? mobileLogo : logo}
            alt="logo"
            className="logo"
          />
        </a>
        &nbsp;&nbsp;
        {screenWidthMobile && (
          <ProductsSearch
            showProductSearch={showProductSearch}
            handleSearchInputChange={handleSearchInputChange}
            searchInput={searchInput}
          />
        )}
        &nbsp;&nbsp;
        <button
          className="modal-drawer-btn"
          id="mobile-drawer"
          onClick={viewMobileDrawer}
        >
          {showMobileDrawer ? (
            <IoMdCloseCircleOutline className="icon" size="20" />
          ) : (
            <RiMenu4Fill className="icon" size="20" />
          )}
        </button>
        <div className="sub-header-right">
          <ProductsSearch
            showProductSearch={showProductSearch}
            handleSearchInputChange={handleSearchInputChange}
            searchInput={searchInput}
          />

          <nav className="page-nav-links">
            {loggedInUser === undefined ? (
              <>
                <button
                  className="link"
                  id="login-ac"
                  onClick={showCustomerLogin}
                >
                  <AiOutlineUserSwitch size="20" className="icon" />
                  &nbsp;
                  <span>Login</span>
                </button>
                <button
                  className="link"
                  id="register-ac"
                  onClick={showCustomerRegistration}
                >
                  <AiOutlineUserAdd size="20" className="icon" />
                  &nbsp;
                  <span>Register</span>
                </button>
              </>
            ) : null}
            <a className="link" href="/product-categories">
              <BiCategoryAlt size="20" className="icon" />
              &nbsp;
              <span>Categories</span>
            </a>
            <a className="link" href="/shop">
              <AiOutlineShop size="20" className="icon" />
              &nbsp; <span>Shop</span>
            </a>

            <a className="link" href="/wishlist">
              <FiHeart size="25" className="icon" />
              {wishlistItems !== undefined && wishlistItems?.length > 0 ? (
                <sup className="notification-badge">
                  {wishlistItems?.length}
                </sup>
              ) : null}
            </a>

            <button
              className="link"
              href="#cart"
              onClick={
                loggedInUser !== undefined ? viewCart : showCustomerLogin
              }
            >
              <FiShoppingCart size="25" className="icon" />
              {remoteCartData?.items !== undefined &&
              remoteCartData?.items?.length > 0 ? (
                <sup className="notification-badge">
                  {remoteCartData?.items?.length}
                </sup>
              ) : null}
            </button>
            {loggedInUser !== undefined ? (
              <span className="avatar-container">
                {loggedInUser?.image_url !== undefined ? (
                  <img
                    src={loggedInUser?.image_url}
                    alt="user"
                    onClick={viewProfileOptions}
                    className="user-avatar"
                  />
                ) : (
                  <button
                    onClick={viewProfileOptions}
                    className="user-avatar-placeholder"
                  >
                    {loggedInUser?.firstName?.charAt(0)}&nbsp;
                    {loggedInUser?.lastName?.charAt(0)}
                  </button>
                )}
                <sub>
                  <FaCaretDown size="20" className="icon" />
                </sub>
              </span>
            ) : null}
          </nav>
        </div>
      </div>
      {showSearchResults && (
        <>
          <SearchResults
            showProductSearch={showProductSearch}
            searchResults={searchResults}
            searching={searching}
            loading={loading}
          />
        </>
      )}

      {showCustomerCart && (
        <>
          <CartModal
            showCustomerLogin={showCustomerLogin}
            loggedInUser={loggedInUser}
            viewCart={viewCart}
            carttItems={userCart}
            dispatch={dispatch}
            setCartItems={setCartItems}
            remoteCartData={remoteCartData}
          />
        </>
      )}
      {showProfileDropdown && (
        <>
          <ProfileModal
            modalEvent={modalEvent}
            viewProfileOptions={viewProfileOptions}
            loggedInUser={loggedInUser}
          />
        </>
      )}
      {showMobileDrawer && (
        <>
          <MenuDrawer
            viewMobileDrawer={viewMobileDrawer}
            modalEvent={modalEvent}
            showCustomerLogin={showCustomerLogin}
            showCustomerRegistration={showCustomerRegistration}
            loggedInUser={loggedInUser}
            wishlistItems={wishlistItems}
            remoteCartData={remoteCartData}
          />
        </>
      )}
      {/* Login Modal */}
      {showLogin && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showLogin}
            // onClose={showCustomerLogin}showCustomerLogin
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showLogin}>
              <Box
                sx={screenWidthMobile ? ModalStyleMobile : ModalStyleDesktop}
              >
                <CustomerLogin
                  closeForm={showCustomerLogin}
                  modalEvent={modalEvent}
                  LoginSuccess={LoginSuccess}
                  loggedInUser={loggedInUser}
                />
              </Box>
            </Fade>
          </Modal>
        </>
      )}

      {/* Registration Modal */}
      {showRegisterCustomer && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showRegisterCustomer}
            // onClose={showViewInvoice}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showRegisterCustomer}>
              <Box
                sx={screenWidthMobile ? ModalStyleMobile : ModalStyleDesktop}
              >
                <CustomerRegister
                  closeForm={showCustomerRegistration}
                  modalEvent={modalEvent}
                  RegistrationSuccess={RegistrationSuccess}
                />
              </Box>
            </Fade>
          </Modal>
        </>
      )}

      {/* Logout Modal */}
      {showLogoutCustomer && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showLogoutCustomer}
            // onClose={showViewInvoice}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showLogoutCustomer}>
              <Box
                sx={screenWidthMobile ? ModalStyleMobile : ModalStyleDesktop}
              >
                <UserLogout
                  closeForm={showCustomerLogout}
                  modalEvent={modalEvent}
                  LogoutSuccess={LogoutSuccess}
                  loggedInUser={loggedInUser}
                />
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </>
  );
}

//Modal styles
const ModalStyleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: `var(--border-radius-2)`,
  padding: 4,
  maxHeight: "85vh",
  overflowY: "auto",
};

const ModalStyleDesktop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "32.5%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: `var(--border-radius-2)`,
  padding: "1rem 1.5rem",
};
