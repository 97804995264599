export const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        ...state,
        userCart: [...state.userCart, { ...action.payload, qty: 1 }],
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        userCart: state.userCart.filter(
          (cartitem) => cartitem?._id !== action.payload._id
        ),
      };
    case "CHANGE_CART_PRODUCT_QTY":
      return {
        ...state,
        userCart: state.userCart.filter((cartitem) =>
          cartitem?._id === action.payload._id
            ? (cartitem.qty = action.payload.qty)
            : cartitem.qty
        ),
      };

    default:
      return state;
  }
};

//wishlist reducer
export const wishlistReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_WISHLIST":
      return {
        ...state,
        userWishlist: [...state.userWishlist, { ...action.payload, qty: 1 }],
      };
    case "REMOVE_FROM_WISHLIST":
      return {
        ...state,
        userWishlist: state.userWishlist.filter(
          (wishlistItem) => wishlistItem?._id !== action.payload._id
        ),
      };

    default:
      return state;
  }
};
