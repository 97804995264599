import axios from "axios";
import { ENDPOINTS } from "../constants/endpoints";
import { useMutation } from "react-query";
var baseURL = process.env.REACT_APP_API_URL;

//call back function to login user
const authorizeUser = (logindata) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = baseURL + ENDPOINTS.login;
  return axios.post(url, logindata, { headers: headers });
};

//Login submission hook
export const SendLogin = (onSuccess, onError) => {
  return useMutation(authorizeUser, {
    onSuccess,
    onError,
  });
};

//call back function to signup user
const authenticateUser = (signupdata) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = baseURL + ENDPOINTS.register;
  return axios.post(url, signupdata, { headers: headers });
};

//Login submission hook
export const SendSignup = (onSuccess, onError) => {
  return useMutation(authenticateUser, {
    onSuccess,
    onError,
  });
};

//call back function to signup user
const VerifyUserEmail = (verificationData) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = baseURL + ENDPOINTS.verifymail + `?${verificationData}`;
  return axios.get(url, { headers: headers });
};

//Login submission hook
export const UpdateMailVerification = (onSuccess, onError) => {
  return useMutation(VerifyUserEmail, {
    onSuccess,
    onError,
  });
};

//call back function to logout user
const RevokeRefreshToken = (userId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = baseURL + ENDPOINTS.logout;
  return axios.post(url, userId, { headers: headers });
};

//Login submission hook
export const LogoutAllDevices = (onSuccess, onError) => {
  return useMutation(RevokeRefreshToken, {
    onSuccess,
    onError,
  });
};

//call back function to requets password reset / forgot password
const forgotPassword = (forgotPassowrdData) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = baseURL + ENDPOINTS.forgotpassword;
  return axios.post(url, forgotPassowrdData, { headers: headers });
};

//Login submission hook
export const SendForgotPasswordData = (onSuccess, onError) => {
  return useMutation(forgotPassword, {
    onSuccess,
    onError,
  });
};

//call back function to change customer password
const customerPasswordReset = (passwordresetdata) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = baseURL + ENDPOINTS.resetpassword;
  return axios.patch(url, passwordresetdata, { headers: headers });
};

//Login submission hook
export const SendPasswordResetData = (onSuccess, onError) => {
  return useMutation(customerPasswordReset, {
    onSuccess,
    onError,
  });
};
