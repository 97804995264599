import React, { useState } from "react";
import "../../../styles/Authentication.css";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { FaArrowAltCircleRight, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import logo from "../../../assets/images/big-sack-logo-abbrev-white-interlay.svg";
import Cookies from "universal-cookie";
import Joi from "joi-browser";
import { validation } from "../../../helpers/validation";
import { SendLogin } from "../../../hooks/authenticationDataHook";
import { ButtonLoader } from "../../globals/ui/PreLoader";

export default function CustomerLogin({ closeForm, modalEvent, LoginSuccess }) {
  const cookies = new Cookies();

  const [showPass, setShowPass] = useState(false);

  //toggle password show
  const togglePasswordShow = () => {
    setShowPass(!showPass);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  //component states
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  //defined validation schema
  const schema = {
    email: Joi.string().required().email(),
    password: Joi.string().required(),
  };

  //Form inputs event handler
  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  //function to submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validation(formdata, schema);
    setErrors(errors || {});
    if (!errors) {
      loginAction(formdata);
    } else {
      console.log(errors);
    }
  };

  const onSuccess = (data) => {
    LoginSuccess();
    cookies.set("LOG_BS_AC_TK", data.data.accessToken);
    cookies.set("LOG_BS_RF_TK", data.data.refreshToken);
    cookies.set("LOG_BS_US", data.data.user);
    closeForm();
    setTimeout(() => 4000, window.location.reload());
    window.location.href = "/";
  };

  const onError = (error) => {
    console.log("error: ", error.message);
  };

  const {
    mutate: loginAction,
    isLoading,
    isError,
    error,
    isSuccess,
  } = SendLogin(onSuccess, onError);

  return (
    <div className="auth-form">
      <div className="auth-header">
        <span>
          <img src={logo} alt="logo" className="logo" />
          <sup>
            <AiOutlineUserSwitch size="20" className="icon" />
          </sup>
        </span>
        <h3>Login to continue</h3>
        <button className="close-btn" onClick={closeForm} disabled={isLoading}>
          {" "}
          <MdCancel size="30" className="icon" />
        </button>
      </div>
      <form onSubmit={handleSubmit} className="auth-form-group">
        {isSuccess ? (
          <span className="small-text success">
            Login succecssful. Have fun shopping!
          </span>
        ) : isError ? (
          <span className="small-text danger">
            {error?.response?.data?.error}
          </span>
        ) : null}
        <div className="input-group">
          <label>Email</label>&nbsp;
          <span className="form-errors">
            {errors.email && "should be valid and is  required"}{" "}
          </span>
          <div className="text-input-wrapper">
            <input
              onChange={handleChange}
              type="email"
              className="input-field"
              placeholder="Your email address"
              name="email"
              readOnly={isLoading ? true : false}
            />
          </div>
        </div>
        <div className="input-group">
          <label>Password</label>&nbsp;
          <span className="form-errors">
            {errors.password && " should be at least 8 characters"}{" "}
          </span>
          <div className="password-input-wrapper">
            <input
              onChange={handleChange}
              id="password"
              type="password"
              className="input-field"
              placeholder="Login password"
              // minLength={8}
              name="password"
            />
            <button
              onClick={() => togglePasswordShow()}
              className="show-password"
              type="button"
            >
              {showPass === true ? (
                <FaRegEyeSlash size="20" />
              ) : (
                <FaRegEye size="20" />
              )}
            </button>
          </div>
        </div>
        <button className="submit-btn" type="submit" disabled={isLoading}>
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <>
              Login &nbsp;
              <FaArrowAltCircleRight size="25" className="icon" />
            </>
          )}
        </button>
      </form>
      <div className="auth-other-actions">
        <a href="/bigsack/customer/forgot-password" className="link">
          Forgot Password
        </a>

        <button
          type="button"
          onClick={modalEvent?.displayRegistration}
          disabled={isLoading}
        >
          Don't have an account? Click here to register...
        </button>
      </div>
    </div>
  );
}
