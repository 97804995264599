//*** */
export const ENDPOINTS = {
  /*system data summary*/
  summary: "summary",
  /*User and profile management endpoints*/
  allusers: "users",
  createuser: "auth/create-user",
  changerole: "users/change-role",

  register: "auth/register",
  login: "auth/login",
  verifymail: "auth/verify-email",
  logout: "auth/revoke-refresh-token",

  forgotpassword: "auth/forgot-password",
  resetpassword: "auth/reset-password",
  userprofile: "users/profile/details",
  updateprofile: "users/profile/update",
  changeprofilesecurity: "users/profile/change-password",

  refreshtoken: "auth/refresh-token",
  revoketoken: "auth/revoke-refresh-token",

  /*Products*/
  products: "products",

  /*Categories*/
  categories: "category",

  /*Promotions*/
  promotions: "promotions",

  /*Payments*/
  payments: "payment",
  loggedinuserpayments: "payment/user/mypayments",

  /*FAQs*/
  faqs: "faq",

  /*delivery locations*/
  locales: "delivery",

  /*send bigsack a mail*/
  mailbigsack: "utils/email",

  /*Cart*/
  cart: "cart",
  usercart: "cart/user/",
  loggedinusercart: "cart/user/mycart/get",
  payment: "payment",
  confirmotp: "payment/otp/send",

  /*Wishlist*/
  wishlist: "wishlist",
  userwishlist: "wishlist/user/",
  loggedinuserwishlist: "wishlist/user/mywishlist/get",
};
