const landingPageMeta = {
  title: "Home - Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Featured Products. New Additions. Explore our wide range of naturally grown and carefully selected fruits and vegetables. Buy high-quality meat, chicken and seafood products at attractive prices. Big Sack Ltd`,
};
const shoppingPageMeta = {
  title: "Shop - Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Shop on Big Sack. Explore our wide range of naturally grown and carefully selected fruits and vegetables. Buy high-quality meat, chicken and seafood products at attractive prices. Big Sack Ltd`,
};
const productDetailPageMeta = {
  title: "Shop - Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Shop product`,
};
const categoriesPageMeta = {
  title:
    "Product Categories - Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Grocery product categories. Explore our wide range of naturally grown and carefully selected fruits and vegetables. Buy high-quality meat, chicken and seafood products at attractive prices. Big Sack Ltd`,
};
const contactPageMeta = {
  title: "Contact Big Sack - Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Contact us. Drop us a message or call us. Contact Big Sack: Online Grocery Shopping on: 0598617329, 0598617329. Big Sack Ltd`,
};
const faqPageMeta = {
  title:
    "FAQs on Big Sack - Frequently asked questions - Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Big Sack Registration. Big Account Related. Big Sack Payments. Big Sack Customer. Big Sack Delivery: East Legon,
Adjiringanor,
Trasacco,
North Legon,
West Legon,
Legon Campus,
Airport Residential,
Cantonments,
Airport Hills,
Roman Ridge`,
};
const aboutPageMeta = {
  title: "About Big Sack- Big Sack: Online Grocery Shopping at Best Prices.",
  metaContent: `Big Sack is an E-commerce business that provides home delivery service for a wide range of groceries particularly fruits, vegetables and meat... Big Sack Ltd`,
};

export const SEOMETA = {
  landingPageMeta,
  shoppingPageMeta,
  productDetailPageMeta,
  categoriesPageMeta,
  contactPageMeta,
  faqPageMeta,
  aboutPageMeta,
};
