import { BsFillPatchCheckFill, BsQuestionDiamondFill } from "react-icons/bs";
import { MdError } from "react-icons/md";

export const CustomSuccessNotif = ({ notification_message }) => {
  var icon_size = 20;
  return (
    <span className="small-text custom-success-notification">
      <BsFillPatchCheckFill size={icon_size} className="icon" />
      &nbsp; {notification_message} !
    </span>
  );
};

export const CustomErrorNotif = ({ notification_message }) => {
  var icon_size = 20;
  return (
    <span className="small-text custom-error-notification">
      <MdError size={icon_size} className="icon" />
      &nbsp; {notification_message} !
    </span>
  );
};

export const CustomUnknownNotif = ({ notification_message }) => {
  var icon_size = 20;
  return (
    <span className="small-text custom-unknown-notification">
      <BsQuestionDiamondFill size={icon_size} className="icon" />
      &nbsp; {notification_message} !
    </span>
  );
};
