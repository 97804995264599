import { BsWhatsapp, BsFacebook, BsInstagram } from "react-icons/bs";
const accounts = [
  {
    title: "Login",
    link: "#mobile-drawer",
  },
  {
    title: "Register",
    link: "#mobile-drawer",
  },
  {
    title: "Forgot Password",
    link: "/bigsack/customer/forgot-password",
  },
];

const corporate = [
  {
    title: "Sell with BigSack",
    link: "/bigsack/customer/help",
  },
  {
    title: "Become an affiliate",
    link: "/bigsack/affiliates",
  },
  {
    title: "Careers at BigSack",
    link: "/careers",
  },
];

const shopping = [
  {
    title: "All Products",
    link: "/shop",
  },
  {
    title: " Product Categories",
    link: "/product-categories",
  },
];

const about = [
  // {
  //   title: "About BigSack",
  //   link: "/about",
  // },
  {
    title: "Contact us",
    link: "/contact",
  },
  {
    title: "FAQ",
    link: "/faq",
  },
  {
    title: "Terms and conditions",
    link: "/terms-conditions-policies",
  },
];

const socialConnect = [
  {
    title: "Facebook",
    link: "https://facebook.com/profile.php?id=100088742125766",
    icon: (
      <BsFacebook size={25} className="icon" style={{ color: "#0165E1" }} />
    ),
  },
  {
    title: "Instagram",
    link: "https://instagram.com/bigsack.gh",
    icon: (
      <BsInstagram size={25} className="icon" style={{ color: "#C13584" }} />
    ),
  },
  {
    title: "WhatsApp",
    link: "https://wa.me/message/FQGFBNEQ4EI3J1",
    icon: (
      <BsWhatsapp
        size={25}
        className="icon"
        style={{ color: `var(--success)` }}
      />
    ),
  },
];

export { accounts, corporate, shopping, about, socialConnect };
