import React from "react";
import "../../../styles/Authentication.css";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import logo from "../../../assets/images/big-sack-logo-abbrev-white-interlay.svg";

import { ButtonLoader } from "../../globals/ui/PreLoader";
import Cookies from "universal-cookie";
import {
  CustomErrorNotif,
  CustomSuccessNotif,
  CustomUnknownNotif,
} from "../../globals/ui/CustomNotifications";
import { LogoutAllDevices } from "../../../hooks/authenticationDataHook";

export default function UserLogout({
  closeForm,
  modalEvent,
  loggedInUser,
  LogoutSuccess,
}) {
  //initialize cookies
  const cookies = new Cookies();

  //logout request body
  const logout_request = {
    userId: loggedInUser?._id,
  };
  //function to submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    sendLogoutRequest(logout_request);
  };

  //on successful submission
  const onSuccess = async (data) => {
    if (data?.status === 200) {
      cookies.remove("LOG_BS_AC_TK", data.data.accessToken);
      cookies.remove("LOG_BS_RF_TK", data.data.refreshToken);
      cookies.remove("LOG_BS_US", data.data.user);
      LogoutSuccess();
      closeForm();
      window.location.reload("/");
    }
  };

  //on error before, while, after submission
  const onError = (error) => {
    console.log("error", error);
  };

  //request handler
  const {
    mutate: sendLogoutRequest,
    isLoading,
    isError,
    error,
    isSuccess,
  } = LogoutAllDevices(onSuccess, onError);

  return (
    <div className="auth-form">
      <div className="auth-header">
        <span>
          <img src={logo} alt="logo" className="logo" />
          <sup>
            <AiOutlineUserAdd size="20" className="icon" />
          </sup>
        </span>
        <h4>Hey, {loggedInUser?.firstName}, logging out?</h4>

        <button
          type="button"
          className="close-btn"
          onClick={closeForm}
          disabled={isLoading}
        >
          {" "}
          <MdCancel size="30" className="icon" />
        </button>
      </div>{" "}
      {isSuccess ? (
        <CustomSuccessNotif notification_message="Account verification successful. Please login to continue..." />
      ) : isError && error?.response?.data !== undefined ? (
        <CustomErrorNotif notification_message={error?.response?.data?.error} />
      ) : isError && error?.response?.data === undefined ? (
        <CustomUnknownNotif notification_message={error.message} />
      ) : null}
      <form onSubmit={handleSubmit} className="auth-form-group">
        <div className="input-row">
          <div className="input-col">
            <div className="input-group">
              <button
                className="submit-btn"
                type="button"
                disabled={isLoading}
                onClick={closeForm}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="input-col">
            <div className="input-group">
              <button className="submit-btn" type="submit" disabled={isLoading}>
                {isLoading ? <ButtonLoader /> : <>Yes...</>}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
