import { createContext, useContext, useState } from "react";

const WishListContext = createContext();

//Context wrapper
export const WishListContextProvider = ({ children }) => {
  //admin headers
  const sessionState = {
    wishlist:
      typeof window !== "undefined"
        ? localStorage.getItem("__user_wishlist")
        : [],
  };

  const [wishlist, setWishList] = useState(
    JSON.parse(sessionState?.wishlist) ?? []
  );

  console.log("context wishishlist", wishlist);
  return (
    <WishListContext.Provider value={{ wishlist, setWishList }}>
      {children}
    </WishListContext.Provider>
  );
};

//Cart state
export const WishListState = () => {
  return useContext(WishListContext);
};
