import React, { useEffect, useState } from "react";

import { CgMoreO } from "react-icons/cg";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";

import { PrimaryCurrency } from "../../../../constants/currency";
import { CartState } from "../../../../helpers/store/ContextStore/cart/StoreProvider";
import "../../../../styles/customers/Cart.css";
import {
  DeleteUserCartItemHook,
  GetUserCart,
} from "../../../../hooks/customerShopData";
import {
  CartItemRemovalFailure,
  CartItemRemovalSuccessful,
} from "../../../globals/ui/cart/cartAdditionNotifications";
import { getRemoteCart } from "../../../../helpers/store/ContextStore/cart/cartActions";
import ProcessingCartItem from "../../../globals/ui/cart/processingCart";

export const CartModal = ({
  viewCart,
  carttItems,
  setCartItems,
  showCustomerLogin,
  loggedInUser,
  remoteCartData,
}) => {
  //show cart state
  const [showProcessingCart, setShowProcessingCart] = useState(false);
  const displayCart = () => {
    setShowProcessingCart(!showProcessingCart);
  };

  //delete cart item api request
  const handleDeleteCartItem = async (product) => {
    // console.log("product to delete", product);
    //     return;
    setShowProcessingCart(true);
    deleteCartProducts({
      product: product?.product?._id,
    });
  };

  //on successful submission
  const onCartItemDeleteSuccess = async (data) => {
    getRemoteCart();
    CartItemRemovalSuccessful();
    setShowProcessingCart(false);
  };

  //on error before, while, after submission
  const onCartItemDeleteError = (error) => {
    CartItemRemovalFailure();
  };

  //delete request handler
  const {
    mutate: deleteCartProducts,
    isLoading: cartDeleting,
    isError: isCartDeletionError,
    error: cartDeletionError,
    isSuccess: isCartDeletionSuccess,
  } = DeleteUserCartItemHook(onCartItemDeleteSuccess, onCartItemDeleteError);
  //========> new cart update functionality <===============//

  return (
    <div className="cart-modal">
      {" "}
      <div className="close-container">
        <button className="modal-close-btn" onClick={viewCart}>
          <IoMdCloseCircle size="25" className="icon" />
        </button>
      </div>
      <span className="cart-modal-header">
        <h4>
          <FiShoppingCart className="icon" size="25" />
          &nbsp;{" "}
          {remoteCartData && (
            <>Cart items {`(${remoteCartData?.items?.length})`}</>
          )}
        </h4>
        {remoteCartData?.items?.length === 0 ? null : (
          <a
            className="link"
            href="/cart"
            style={{
              color: `var(--global-black)`,
              fontSize: `var(--font-normal)`,
            }}
            onClick={viewCart}
          >
            Show all details &nbsp;
            <CgMoreO size="15" className="icon" />
          </a>
        )}{" "}
      </span>
      <div className="cart-body">
        {remoteCartData?.items?.length === 0 ? (
          <span
            style={{
              color: `var(--global-black)
          `,
              fontStyle: "italic",
            }}
          >
            Your cart happens to be empty
          </span>
        ) : (
          <>
            {remoteCartData?.items?.map((item, index) => {
              return (
                <div className="modal-cart-item" key={index + 1}>
                  {item?.product?.image_url === undefined ? (
                    <FiShoppingCart size="25" className="icon" />
                  ) : (
                    <img
                      src={item?.product?.image_url}
                      alt="product"
                      className="product-image"
                    />
                  )}
                  <span className="modal-cart-item-detail">
                    {item?.product?.name?.length > 10
                      ? `${item?.product?.name?.slice(0, 10)}...`
                      : item?.product?.name}{" "}
                  </span>
                  <span className="quantity">{item?.quantity}*</span>

                  {item?.product?.discounted_price ? (
                    <span className="quantity">
                      {PrimaryCurrency}{" "}
                      <sup style={{ textDecoration: "line-through" }}>
                        {item?.product?.price}
                      </sup>{" "}
                      {item?.product?.discounted_price}
                    </span>
                  ) : (
                    <span className="quantity">
                      {PrimaryCurrency} {item?.product?.price}
                    </span>
                  )}
                  <button onClick={() => handleDeleteCartItem(item)}>
                    <FaRegTrashAlt className="icon" size="15" />
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>
      <>
        {remoteCartData?.items?.length === 0 ? null : (
          <a href="/cart" className="modal-cart-checkout-btn">
            Checkout -{" "}
            {remoteCartData?.items?.length > 0 ? (
              <span>
                {PrimaryCurrency}
                {remoteCartData?.totalAmount}
              </span>
            ) : null}
          </a>
        )}
      </>
      <ProcessingCartItem
        showProcessingCart={showProcessingCart}
        displayCart={displayCart}
        error={cartDeletionError}
        success={isCartDeletionSuccess}
        cartDeleting={cartDeleting}
      />
    </div>
  );
};
