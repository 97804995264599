import React, { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import "../../../styles/customers/CustomerLayout.css";
import Footer from "./Footer";
import Header from "./Header";

export default function CustomerLayout({ children }) {
  //initialize cookies
  const cookies = new Cookies();

  //component states
  const [loggedInUser, setLoggedInUser] = useState({});

  //get logged in user
  var userfromcookie = cookies.get("LOG_BS_US");
  // console.log("user", userfromcookie);

  function getLoggedInUser() {
    setLoggedInUser(userfromcookie);
  }

  //on component mount, update, etc.
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <div className="customer-layout">
      <Header loggedInUser={loggedInUser} />
      {children}
      <Footer />
    </div>
  );
}
