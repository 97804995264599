import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
// import { ReactQueryDevtools } from "react-query/devtools";

import { Context } from "./helpers/store/ContextStore/cart/StoreProvider";
import TermsandConditions from "./components/customers/page-components/Terms/TermsConditions.jsx";
import { WishListContextProvider } from "./helpers/store/ContextStore/cart/wishListProvider";
//Lazy load components and/or component pages
//customer pages
const CustomerLanding = React.lazy(() =>
  import("./components/customers/page-components/Landing")
);

const CustomerVerification = React.lazy(() =>
  import("./components/auth/customers/AccountVerification.jsx")
);

const CustomerForgotPassword = React.lazy(() =>
  import("./components/auth/customers/ForgotPassword.jsx")
);

const CustomerResetPassword = React.lazy(() =>
  import("./components/auth/customers/ResetPassword.jsx")
);

const ShopAllProducts = React.lazy(() =>
  import("./components/customers/page-components/Shop/Shop.jsx")
);

const ShopProductCategories = React.lazy(() =>
  import("./components/customers/page-components/Category/Categories.jsx")
);

const FAQPage = React.lazy(() =>
  import("./components/customers/page-components/FAQ/FAQ.jsx")
);

const ContactPage = React.lazy(() =>
  import("./components/customers/page-components/Contact/Contact.jsx")
);

const AboutPage = React.lazy(() =>
  import("./components/customers/page-components/AboutCompany/AboutBigsack.jsx")
);
const Policies = React.lazy(() =>
  import("./components/customers/page-components/Terms/TermsConditions.jsx")
);

const WishList = React.lazy(() =>
  import("./components/customers/page-components/WishList/CustomerWishlist.jsx")
);
const ProductDetails = React.lazy(() =>
  import("./components/customers/page-components/Checkout/ProductDetail.jsx")
);

const PendingOrders = React.lazy(() =>
  import("./components/customers/page-components/Orders/CustomerCart.jsx")
);

const OrdersCheckoutPage = React.lazy(() =>
  import("./components/customers/page-components/Orders/OrderCheckout.jsx")
);

const OrdersCheckoutAddreessConfirmation = React.lazy(() =>
  import("./components/customers/page-components/Orders/OrderCheckout.jsx")
);

const OrderHistory = React.lazy(() =>
  import(
    "./components/customers/page-components/Orders/CustomerOrderHistory.jsx"
  )
);
const UserAccountDashboard = React.lazy(() =>
  import(
    "./components/customers/page-components/AccountManagement/ManagementPage.jsx"
  )
);
//Admin pages
const AdminLogin = React.lazy(() =>
  import("./components/auth/admin/Login.jsx")
);
const AdminVerification = React.lazy(() =>
  import("./components/auth/admin/AccountVerification.jsx")
);

const AdminForgotPassword = React.lazy(() =>
  import("./components/auth/admin/ForgotPassword.jsx")
);
const AdminResetPassword = React.lazy(() =>
  import("./components/auth/admin/Resetpassword.jsx")
);

// -----Admin management pages-------//
const AdminDashboard = React.lazy(() =>
  import("./components/admin/dashboard/index.js")
);

const UserManagement = React.lazy(() =>
  import("./components/admin/users/Users.jsx")
);
const ProductsManagement = React.lazy(() =>
  import("./components/admin/products/Products.jsx")
);
const ProductCategories = React.lazy(() =>
  import("./components/admin/productcategories/Categories.jsx")
);
const Promotions = React.lazy(() =>
  import("./components/admin/promotions/Promotions.jsx")
);
const Invoices = React.lazy(() =>
  import("./components/admin/Invoices/Invoices.jsx")
);
const Payments = React.lazy(() =>
  import("./components/admin/Payments/Payments.jsx")
);
const AdminFAQs = React.lazy(() =>
  import("./components/admin/faq/AllFAQs.jsx")
);
const AdminLocations = React.lazy(() =>
  import("./components/admin/deliveryLocale/AllLocales.jsx")
);
const ProfileSettings = React.lazy(() =>
  import("./components/admin/settings/Settings.jsx")
);
const NotFoundFallback = React.lazy(() =>
  import("./components/globals/ui/PageNotFound.js")
);

export default function BigSackApp() {
  return (
    <Router>
      <Switch>
        {/*--- Admin routes ---*/}
        <Route path="/bigsack/admin/login" exact component={AdminLogin} />{" "}
        <Route
          path="/bigsack/admin/verify"
          exact
          component={AdminVerification}
        />{" "}
        <Route
          path="/bigsack/admin/forgot-password"
          exact
          component={AdminForgotPassword}
        />
        <Route
          path="/bigsack/admin/reset-password"
          exact
          component={AdminResetPassword}
        />
        {/* Admin management */}
        <Route
          path="/bigsack/admin/dashboard"
          exact
          component={AdminDashboard}
        />
        <Route path="/bigsack/admin/users" exact component={UserManagement} />
        <Route
          path="/bigsack/admin/products"
          exact
          component={ProductsManagement}
        />
        <Route
          path="/bigsack/admin/product-categories"
          exact
          component={ProductCategories}
        />
        <Route path="/bigsack/admin/promotions" exact component={Promotions} />
        <Route path="/bigsack/admin/invoices" exact component={Invoices} />
        <Route path="/bigsack/admin/payments" exact component={Payments} />
        <Route path="/bigsack/admin/faqs" exact component={AdminFAQs} />
        <Route
          path="/bigsack/admin/delivery-locations"
          exact
          component={AdminLocations}
        />
        <Route
          path="/bigsack/admin/settings"
          exact
          component={ProfileSettings}
        />
        {/*--- Customer routes ---*/}
        <WishListContextProvider>
          <Context>
            {" "}
            <Route path="/" exact component={CustomerLanding} />
            <Route
              path="/bigsack/customer/verify"
              exact
              component={CustomerVerification}
            />{" "}
            <Route
              path="/bigsack/customer/forgot-password"
              exact
              component={CustomerForgotPassword}
            />
            <Route
              path="/bigsack/customer/reset-password"
              exact
              component={CustomerResetPassword}
            />
            <Route
              path="/bigsack/customer/help"
              exact
              component={CustomerResetPassword}
            />
            <Route path="/faq" exact component={FAQPage} />
            <Route path="/contact" exact component={ContactPage} />
            <Route path="/about" exact component={AboutPage} />
            <Route
              path="/terms-conditions-policies"
              exact
              component={TermsandConditions}
            />
            <Route path="/shop" exact component={ShopAllProducts} />
            <Route
              path="/product-categories"
              exact
              component={ShopProductCategories}
            />
            <Route path="/product" exact component={ProductDetails} />
            <Route path="/wishlist" exact component={WishList} />
            <Route path="/cart" exact component={PendingOrders} />
            <Route
              path="/order-checkout"
              exact
              component={OrdersCheckoutPage}
            />
            <Route
              path="/order-checkout/confirm-address"
              exact
              component={OrdersCheckoutAddreessConfirmation}
            />
            <Route path="/order-history" exact component={OrderHistory} />
            <Route
              path="/account-management"
              exact
              component={UserAccountDashboard}
            />{" "}
          </Context>
        </WishListContextProvider>
        <Route path="/404" component={NotFoundFallback} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}
