import React, { useState } from "react";
import "../../../styles/Authentication.css";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaArrowAltCircleRight, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import Joi from "joi-browser";
import { validation } from "../../../helpers/validation";
import logo from "../../../assets/images/big-sack-logo-abbrev-white-interlay.svg";
import country from "../../../helpers/countries.json";

import { SendSignup } from "../../../hooks/authenticationDataHook";
import { ButtonLoader } from "../../globals/ui/PreLoader";

export default function CustomerRegister({
  closeForm,
  modalEvent,
  RegistrationSuccess,
}) {
  const [showPass, setShowPass] = useState(false);

  //toggle password show
  const togglePasswordShow = () => {
    setShowPass(!showPass);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  //component states
  const [formdata, setFormdata] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    country_code: "",
    mobile: "",
    city: "",
    company: "",
    zip: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  //defined validation schema
  const schema = {
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string().required().email(),
    country: Joi.string().required(),
    city: Joi.string().required(),

    country_code: Joi.string().required(),
    mobile: Joi.string().required().min(5),
    company: Joi.optional().allow(null),
    zip: Joi.optional().allow(null),
    password: Joi.string().required(),
    confirm_password: Joi.string().required().valid(Joi.ref("password")),
  };

  //Form inputs event handler
  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  //customer data to submit
  const userdata = {
    firstName: formdata.firstName,
    lastName: formdata.lastName,
    email: formdata.email,
    country: formdata.country,
    phone: `${formdata.country_code}${formdata.mobile}`,
    city: formdata.city,
    company: formdata.company,
    zip: formdata.zip,
    password: formdata.password,
  };

  //function to submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validation(formdata, schema);
    setErrors(errors || {});
    if (!errors) {
      if (formdata.company === null || "" || "undefined") {
        delete userdata.company;
        sendCreateUserRequest(userdata);
      } else {
        sendCreateUserRequest(userdata);
      }
    } else {
      console.log(errors);
    }
  };

  //on successful submission
  const onSuccess = async (data) => {
    RegistrationSuccess();
    // console.log("success: ", data);
    closeForm();
  };

  //on error before, while, after submission
  const onError = (error) => {
    console.log("error", error);
  };

  //request handler
  const {
    mutate: sendCreateUserRequest,
    isLoading,
    isError,
    error,
    isSuccess,
  } = SendSignup(onSuccess, onError);

  return (
    <div className="auth-form">
      <div className="auth-header">
        <span>
          <img src={logo} alt="logo" className="logo" />
          <sup>
            <AiOutlineUserAdd size="20" className="icon" />
          </sup>
        </span>
        {isSuccess ? (
          <span className="small-text success">Account registered !</span>
        ) : isError ? (
          <span className="small-text danger">
            {error?.response?.data?.error}
          </span>
        ) : (
          <h3>Register here</h3>
        )}

        <button className="close-btn" onClick={closeForm} disabled={isLoading}>
          {" "}
          <MdCancel size="30" className="icon" />
        </button>
      </div>
      <form onSubmit={handleSubmit} className="auth-form-group">
        <div className="input-row">
          <div className="input-col">
            <div className="input-group">
              <label>
                First name<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;
              <span className="form-errors">
                {errors.firstName && " is required"}{" "}
              </span>
              <div className="text-input-wrapper">
                <input
                  onChange={handleChange}
                  type="text"
                  className="input-field"
                  placeholder="Eg: Josh"
                  name="firstName"
                  readOnly={isLoading ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="input-col">
            <div className="input-group">
              <label>
                Last name<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;
              <span className="form-errors">
                {errors.lastName && " is required"}{" "}
              </span>
              <div className="text-input-wrapper">
                <input
                  onChange={handleChange}
                  type="text"
                  className="input-field"
                  placeholder="Eg: Sack"
                  name="lastName"
                  readOnly={isLoading ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-row">
          <div className="input-col">
            <div className="input-group">
              <label>
                City<sup style={{ color: "red" }}>*</sup>
              </label>{" "}
              <span className="form-errors">
                &nbsp;
                {errors.city && "is required"}{" "}
              </span>
              <div className="text-input-wrapper">
                <input
                  onChange={handleChange}
                  type="text"
                  className="input-field"
                  placeholder="City"
                  name="city"
                  readOnly={isLoading ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="input-col">
            <div className="input-group">
              <label>
                Country<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;{" "}
              <span className="form-errors">
                {errors.country && " is required"}{" "}
              </span>
              <div className="text-input-wrapper">
                <select
                  name="country"
                  className="form-control"
                  onChange={handleChange}
                  disabled={isLoading ? true : false}
                  defaultValue="default"
                >
                  <option disabled value="default">
                    Select a country
                  </option>
                  {/* {country.map((country, index) => (
                    <option key={index + 1} value={country?.name}>
                      {country?.name}
                    </option>
                  ))} */}
                  <option value={country[83]?.name}>{country[83]?.name}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="input-row">
          <div className="input-col">
            <div className="input-group">
              <label>
                Zip / Residential Address<sup style={{ color: "red" }}>*</sup>
              </label>
              <div className="text-input-wrapper">
                <input
                  onChange={handleChange}
                  type="text"
                  className="input-field"
                  placeholder="Zip"
                  name="zip"
                  readOnly={isLoading ? true : false}
                />
              </div>
              {/* <span className="form-errors">
                {errors.zip && "Please enter a valid zip code"}{" "}
              </span> */}
            </div>
          </div>
          <div className="input-col">
            <div className="input-group">
              <label>Company </label>
              <div className="text-input-wrapper">
                <input
                  onChange={handleChange}
                  type="text"
                  className="input-field"
                  placeholder="For companies only"
                  name="company"
                  readOnly={isLoading ? true : false}
                />
              </div>
              {/* <span className="form-errors">
                {errors.company && "A company is required"}{" "}
              </span> */}
            </div>
          </div>
        </div>
        <div className="input-row">
          <div className="input-col">
            <div className="input-group">
              <label>
                Country code<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;{" "}
              <span className="form-errors">
                {errors.mobile && "is required"}{" "}
              </span>
              <div className="text-input-wrapper">
                <select
                  name="country_code"
                  className="form-control mb-1"
                  onChange={handleChange}
                  disabled={isLoading ? true : false}
                  defaultValue="default"
                >
                  <option disabled value="default">
                    Code...
                  </option>
                  {/* {country.map((code, index) => (
                    <option key={index + 1} value={`+${code?.phone}`}>
                      +{code?.phone} {`(${code?.code})`} - {code?.name}
                    </option>
                  ))} */}
                  <option value={`+${country[83]?.phone}`}>
                    +{country[83]?.phone} {`(${country[83]?.code})`} -{" "}
                    {country[83]?.name}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="input-col">
            <div className="input-group">
              <label>
                Phone<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;
              <span className="form-errors">
                {errors.mobile && "is required"}{" "}
              </span>
              <div className="text-input-wrapper">
                <input
                  onChange={handleChange}
                  type="text"
                  className="input-field"
                  placeholder="Eg: 257896564"
                  name="mobile"
                  readOnly={isLoading ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-group">
          <label>
            Email<sup style={{ color: "red" }}>*</sup>
          </label>
          &nbsp;
          <span className="form-errors">
            {errors.email && "should be valid and is  required"}{" "}
          </span>
          <div className="text-input-wrapper">
            <input
              onChange={handleChange}
              type="email"
              className="input-field"
              placeholder="Your email address"
              name="email"
              readOnly={isLoading ? true : false}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="input-col">
            <div className="input-group">
              <label>
                Password<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;{" "}
              <span className="form-errors">
                {errors.password && "At least 8 characters"}{" "}
              </span>
              <div className="password-input-wrapper">
                <input
                  onChange={handleChange}
                  id="password"
                  type="password"
                  className="input-field"
                  placeholder="Enter password"
                  // minLength={8}
                  name="password"
                />
                <button
                  style={{ marginRight: "0.3rem" }}
                  onClick={togglePasswordShow}
                  className="show-password"
                  type="button"
                >
                  {showPass === true ? (
                    <FaRegEyeSlash size="20" />
                  ) : (
                    <FaRegEye size="20" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="input-col">
            <div className="input-group">
              <label>
                Confirm Password<sup style={{ color: "red" }}>*</sup>
              </label>
              &nbsp;{" "}
              <div
                className="password-input-wrapper"
                style={errors.confirm_password && { border: "1px solid red" }}
              >
                <input
                  onChange={handleChange}
                  type="password"
                  className="input-field"
                  placeholder="Confirm password"
                  name="confirm_password"
                />
              </div>
            </div>
          </div>
        </div>
        <button className="submit-btn" type="submit" disabled={isLoading}>
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <>
              Register &nbsp;
              <FaArrowAltCircleRight size="25" className="icon" />
            </>
          )}
        </button>
      </form>
      <div className="auth-other-actions">
        <button onClick={modalEvent?.displayLogin} type="button">
          Have an account? Click here to login...
        </button>
      </div>
    </div>
  );
}
