import axios from "axios";
import Cookies from "universal-cookie";
import {
  CartItemAdditionSuccessful,
  CartItemRemovalSuccessful,
} from "../../../../components/globals/ui/cart/cartAdditionNotifications";
import { ENDPOINTS } from "../../../../constants/endpoints";
//initialize cookies
const cookies = new Cookies();
var baseURL = process.env.REACT_APP_API_URL;

//add product to cart functionality
export const addProductToCart = (dispatch, product) => {
  dispatch({
    type: "ADD_TO_CART",
    payload: product,
  });
  CartItemAdditionSuccessful();
};

//remove product from cart functionality
export const removeProductFromCart = (dispatch, product) => {
  dispatch({
    type: "REMOVE_FROM_CART",
    payload: product,
  });
  CartItemRemovalSuccessful();
};

// Decrease cart item purchase quantity
export const decreaseCartItemQuantity = (dispatch, product) => {
  // e.preventDefault();
  if (product.qty > 1) {
    dispatch({
      type: "CHANGE_CART_PRODUCT_QTY",
      payload: { _id: product?._id, qty: product.qty - 1 },
    });
  }
};

// Increase cart item purchase quantity
export const increaseCartItemQuantity = (dispatch, product) => {
  // e.preventDefault();
  if (product.qty < product.quantity) {
    dispatch({
      type: "CHANGE_CART_PRODUCT_QTY",
      payload: { _id: product?._id, qty: product.qty + 1 },
    });
  }
};

/*---FUNCTION TO SYNC DATA TO REMOTE SERVER--*/
//get access token
const ACCESS_TOKEN = cookies.get("LOG_BS_AC_TK");

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${ACCESS_TOKEN}`,
};

export const SyncRemoteandLocalCart = async () => {
  var all_local_cart_items = localStorage.getItem(
    "__bigsack_session_user_cart"
  );
  var local_cart = JSON.parse(all_local_cart_items);
  var cart_items_to_sync = [];
  let local_cart_length = local_cart?.length;
  for (let l = 0; l < local_cart_length; l++) {
    cart_items_to_sync.push({
      product: local_cart[l]._id,
      quantity: local_cart[l].qty,
    });
  }
  //api request body
  const API_REQUEST_BODY = {
    items: cart_items_to_sync,
  };
  console.log("request body", API_REQUEST_BODY);
  await axios
    .patch(
      `${process.env.REACT_APP_API_URL + ENDPOINTS.loggedinusercart}`,
      API_REQUEST_BODY,
      { headers: headers }
    )
    .then((response) => {
      if (response.status === 200) {
      } else {
        console.log("another response", response);
      }
    });
};

/*--------trigger refetching of cart data------*/
//get remote cart data
export const getRemoteCart = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  axios
    .get(`${process.env.REACT_APP_API_URL + ENDPOINTS.loggedinusercart}`, {
      headers: headers,
    })
    .then((response) => {
      if (response.status === 200) {
        // console.log("cart exists", response.data);
        localStorage.setItem(
          "__bigsack_session_user_cart",
          JSON.stringify(response.data?.items)
        );
        // return user_Cart;
      } else if (response.status === 400 || 404) {
        console.log("cart not initalized");
      } else {
        console.log("something else happened");
      }
    });
};

/*--------trigger refetching of cart data------*/
//get remote cart data
export const ClearRemoteCart = async () => {
  var cart_id = localStorage.getItem("__bigsack_remote_user_cart_id");
  const parsed_cart_id = JSON.parse(cart_id);

  //api request body
  const API_REQUEST_BODY = {
    items: [],
  };
  const url = baseURL + ENDPOINTS.cart + `/${parsed_cart_id}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  delete axios
    .patch(url, API_REQUEST_BODY, {
      headers: headers,
    })
    .then((response) => {
      if (response.status === 200) {
        // console.log("cart exists", response.data);
        localStorage.setItem("__bigsack_session_user_cart", []);
        // return user_Cart;
      } else if (response.status === 400 || 404) {
        console.log("cart not initalized");
      } else {
        console.log("something else happened");
      }
    });
};
