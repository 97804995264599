import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Cookies from "universal-cookie";
import { ENDPOINTS } from "../../../../constants/endpoints";
import { cartReducer } from "./Reducers";

const Cart = createContext();

//Context wrapper
export const Context = ({ children }) => {
  const [cartData, setCartData] = useState({});
  //component states
  const [loggedInUser, setLoggedInUser] = useState({});
  const [user_Cart, setUserCart] = useState([]);

  //initialize cookies
  const cookies = new Cookies();

  //get logged in user
  var userfromcookie = cookies.get("LOG_BS_US");

  function getLoggedInUser() {
    setLoggedInUser(userfromcookie);
  }
  //get access token
  const ACCESS_TOKEN = cookies.get("LOG_BS_AC_TK");

  //get remote cart data
  const getRemoteCart = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_URL + ENDPOINTS.loggedinusercart}`, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(
            "__bigsack_remote_user_cart_id",
            JSON.stringify(response.data?._id)
          );
          // return user_Cart;
        } else if (response.status === 400 || 404) {
          console.log("cart not initalized");
        } else {
          console.log("something else happened");
        }
      });
  };

  //get local cart data
  const getLocalCart = () => {
    let localSessionCart = localStorage.getItem("__bigsack_session_user_cart");
    if (localSessionCart == []) {
      return [];
    } else if (!localSessionCart || localSessionCart === undefined || null) {
      return [];
    } else if (localSessionCart.length > 0) {
      // console.log(JSON.parse(localSessionCart));
      return JSON.parse(localSessionCart);
    }
  };

  // var initial_cart = loggedInUser ? getRemoteCart() : getLocalCart();
  //initialize reducer hook
  const [state, dispatch] = useReducer(cartReducer, {
    userCart: getLocalCart(),
    // userWishlist: [],
  });

  //on component mount, update, etc.
  useEffect(() => {
    getLoggedInUser();
    getRemoteCart();
    localStorage.setItem(
      "__bigsack_session_user_cart",
      JSON.stringify(state.userCart)
    );
  }, [state.userCart]);

  return (
    <Cart.Provider value={{ state, dispatch, cartData, setCartData }}>
      {children}
    </Cart.Provider>
  );
};

//Cart state
export const CartState = () => {
  return useContext(Cart);
};
