import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Cookies from "universal-cookie";
import { ENDPOINTS } from "../constants/endpoints";
const cookies = new Cookies();
var baseURL = process.env.REACT_APP_API_URL;

//get access token
const ACCESS_TOKEN = cookies.get("LOG_BS_AC_TK");
// console.log("skls", ACCESS_TOKEN);
/*##===========>SHOPPING PRODUCTS<============##*/
//--> call back function to get all market / shop products
const getAllLandingMarketProducts = (filter_param) => {
  var productsUrl = baseURL + ENDPOINTS.products;
  const url = productsUrl;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetAllLandingMarketProductsData = (
  onSuccess,
  onError,
  filter_param
) => {
  return useQuery(
    ["allProducts", filter_param],
    () => getAllLandingMarketProducts(filter_param),
    {
      onSuccess,
      onError,
    }
  );
};

/*##===========>SHOPPING PRODUCTS<============##*/
//--> call back function to get all market / shop products
const getAllMarketProducts = (filter_param) => {
  var productsUrl = !filter_param
    ? baseURL + ENDPOINTS.products
    : baseURL + ENDPOINTS.products + `/category/${filter_param}`;
  const url = productsUrl;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetAllMarketProductsData = (onSuccess, onError, filter_param) => {
  return useQuery(
    ["allProducts", filter_param],
    () => getAllMarketProducts(filter_param),
    {
      onSuccess,
      onError,
    }
  );
};

/*##===========>SHOPPING PRODUCTS<============##*/
//--> call back function to get all market / shop products
const getAllFilteredMarketProducts = (filter_param) => {
  var productsUrl = baseURL + ENDPOINTS.products + `/category/${filter_param}`;
  const url = productsUrl;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetAllFilteredMarketProductsData = (
  onSuccess,
  onError,
  filter_param
) => {
  return useQuery(
    ["allProducts", filter_param],
    () => getAllFilteredMarketProducts(filter_param),
    {
      onSuccess,
      onError,
    }
  );
};

//--> call back function to get a selected product data
const getProduct = (productID) => {
  const url = baseURL + ENDPOINTS.products + `/${productID}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const SelectedProductData = (onSuccess, onError, productID) => {
  return useQuery(["product", productID], () => getProduct(productID), {
    onSuccess,
    onError,
  });
};

//--> call back function to get a selected product data
const getRelatedProducts = (relatedProductsCatID) => {
  const url =
    baseURL + ENDPOINTS.products + `/category/${relatedProductsCatID}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const SelectedRelatedroductsData = (
  onSuccess,
  onError,
  relatedProductsCatID
) => {
  return useQuery({
    queryKey: ["relatedProducts", relatedProductsCatID],
    queryFn: () => getRelatedProducts(relatedProductsCatID),
    onSuccess: onSuccess,
    onError: onError,
    staleTime: Infinity,
  });
};

/*##===========>SHOPPING PRODUCT  CATEGORIES<============##*/
//--> call back function to get all market / shop products
const getAllMarketCategories = () => {
  const url = baseURL + ENDPOINTS.categories;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetAllMarketCategoriesData = (onSuccess, onError) => {
  return useQuery("allCategories", getAllMarketCategories, {
    onSuccess,
    onError,
  });
};

//--> call back function to get a selected product data
const getCategory = (categoryID) => {
  const url = baseURL + ENDPOINTS.categories + `/${categoryID}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const SelectedCategoryData = (onSuccess, onError, categoryID) => {
  return useQuery(["category", categoryID], () => getCategory(categoryID), {
    onSuccess,
    onError,
  });
};

/*##==========>LOGGED IN USER CART EVENTS<=======##*/

//call back function to get logged in user cart content
const getLoggedInUserCart = () => {
  const url = baseURL + ENDPOINTS.loggedinusercart;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetUserCart = (onSuccess, onError) => {
  return useQuery("mycart", getLoggedInUserCart, {
    queryKey: ["mycart"],
    queryFn: getLoggedInUserCart,
    onSuccess: onSuccess,
    onError: onError,
    staleTime: Infinity,
  });
};

//========> new cart update functionality <===============//

//call back function to update user cart
const updateUserCart = (product) => {
  //api request body
  const API_REQUEST_BODY = {
    items: [product],
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.cart;
  delete API_REQUEST_BODY.cartID;
  return axios.post(url, API_REQUEST_BODY, { headers: headers });
};

//Cart update hook
export const UpdateUserCartHook = (onAddSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(updateUserCart, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["mycart"] });
      queryClient.invalidateQueries({ queryKey: ["allProcuts"] });
    },
    onAddSuccess,
    onError,
  });
};

//========> end of new cart update functionality <===============//

/////===========> !! Delete cart item !! <=================///

//call back function to create user cart
const deleteCartItem = (product) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.cart + "/item/remove";
  return axios.patch(url, product, { headers: headers });
};

//Cart creation hook
export const DeleteUserCartItemHook = (onSuccess, onError) => {
  const queryClient = useQueryClient();

  return useMutation(deleteCartItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["mycart"] });
      queryClient.invalidateQueries({ queryKey: ["allProcuts"] });
    },
    onError,
  });
};

////===========|||||||||||||||=======================//

//initialize cart for logged in user with no cart
//call back function to create user cart
const createCart = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.cart;
  return axios.post(
    url,
    {
      items: [],
    },
    { headers: headers }
  );
};

//Cart creation hook
export const TriggerCartCreation = (onSuccess, onError) => {
  return useMutation(createCart, {
    onSuccess,
    onError,
  });
};

//sync local and remote cart
//call back function to sync user cart
const syncCart = (API_REQUEST_BODY) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.cart + `/${API_REQUEST_BODY.cartID}`;
  delete API_REQUEST_BODY.cartID;
  return axios.patch(url, API_REQUEST_BODY, { headers: headers });
};

//Cart sync hook
export const TriggerCartSync = (onSuccess, onError) => {
  return useMutation(syncCart, {
    onSuccess,
    onError,
  });
};

//call back function to create user cart
const emptyCart = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.cart;
  return axios.patch(
    url,
    {
      items: [],
    },
    { headers: headers }
  );
};

//Cart creation hook
export const EmptyCartHook = (onSuccess, onError) => {
  return useMutation(emptyCart, {
    onSuccess,
    onError,
  });
};

/*##========>LOGGED IN USER WISHLIST EVENTS<============##*/

//get logged in user wishlist content
const getLoggedInUserWishlist = () => {
  const url = baseURL + ENDPOINTS.loggedinuserwishlist;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetUserWishlist = (onSuccess, onError) => {
  return useQuery("mywishlist", getLoggedInUserWishlist, {
    onSuccess,
    onError,
  });
};

//initialize wishlist for logged in user with no wishlist
//call back function to create user wishlist
const createWishlist = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.cart;
  return axios.post(
    url,
    {
      items: [],
    },
    { headers: headers }
  );
};

//wishlist creation hook
export const TriggerWishlistCreation = (onSuccess, onError) => {
  return useMutation(createWishlist, {
    onSuccess,
    onError,
  });
};

/*##================GET DELIVERY LOCATIONS=================##*/
//--> call back function to get all customer delivery locations
const getAllDeliveryLocales = () => {
  const url = baseURL + ENDPOINTS.locales;
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(url, { headers: headers });
};

//Get delivery location hook
export const GetallCustomerDeliveryLocations = (onSuccess, onError) => {
  return useQuery("allcustomerdeliverylocales", getAllDeliveryLocales, {
    onSuccess,
    onError,
  });
};

/*##================MAKE PAYMENT REQUEST EVENTS=================##*/
//call back function to make payment
const MakePayment = (PAYMENT_REQUEST) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.payment;
  return axios.post(url, PAYMENT_REQUEST, { headers: headers });
};

//Payment request hook
export const TriggerOrderPayment = (onSuccess, onError) => {
  return useMutation(MakePayment, {
    onSuccess,
    onError,
  });
};

/*##===============CONFIRM OTP=================##*/
//call back function to make payment
const OTPConfirmation = (otprequest) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  const url = baseURL + ENDPOINTS.confirmotp;
  return axios.post(url, otprequest, { headers: headers });
};

//otp request hook
export const OTPConfirmationHook = (onSuccess, onError) => {
  return useMutation(OTPConfirmation, {
    onSuccess,
    onError,
  });
};

/*##================GET LOGGED IN USER PAYMENT / ORDER HISTORY=================##*/
//--> call back function to get all customer delivery locations
const getAllLoggedInUserOrderPayments = () => {
  const url = baseURL + ENDPOINTS.loggedinuserpayments;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

//Get delivery location hook
export const GetAllLoggedInUserOrderPaymentsHook = (onSuccess, onError) => {
  return useQuery("customerloggedinorders", getAllLoggedInUserOrderPayments, {
    onSuccess,
    onError,
  });
};

/*##================GET PROMOTIONS=================##*/
const getAllShopPromotions = () => {
  const url = baseURL + ENDPOINTS.promotions;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };
  return axios.get(url, { headers: headers });
};

export const GetShopPromoData = (onSuccess, onError) => {
  return useQuery("shopPromotions", getAllShopPromotions, {
    onSuccess,
    onError,
  });
};
