import { MdClose } from "react-icons/md";
import "../../../../styles/customers/Search.css";
import { LoaderIcon } from "react-hot-toast";

export const SearchResults = ({
  showProductSearch,
  searchResults,
  searching,
  loading,
}) => {
  return (
    <div className="products-search">
      <div className="top">
        {searching ? (
          "Search results"
        ) : (
          <span className="search-notif">Search for a product ...</span>
        )}
        <button onClick={() => showProductSearch()}>
          <MdClose size={15} />
        </button>
      </div>

      {loading ? (
        <span className="search-loader">
          Fetching products ...
          <LoaderIcon />
        </span>
      ) : (
        <>
          {searchResults?.map((searchOption, index) => {
            return (
              <a
                href={`/product?${searchOption?._id}&category=${searchOption?.category?._id}`}
                key={index + 1}
                className="search-item"
              >
                {searchOption?.name?.length > 20
                  ? `${searchOption?.name.slice(0, 20)}...`
                  : searchOption?.name}{" "}
                -{" "}
                <span>
                  {searchOption?.description?.length > 30
                    ? `${searchOption?.description.slice(0, 30)}...`
                    : searchOption?.description}{" "}
                </span>
              </a>
            );
          })}
        </>
      )}
    </div>
  );
};
