import { FiSearch } from "react-icons/fi";

export const ProductsSearch = ({ handleSearchInputChange }) => {
  return (
    <div className="search-container">
      <FiSearch size="20" className="icon" />
      <input
        type="search"
        className="search-bar"
        placeholder="Search products"
        onChange={handleSearchInputChange}
      />
    </div>
  );
};
