import secondaryLogo from "../../../assets/images/big-sack-logo-group-III.svg";
import rippleAnimation from "../../../assets/animations/ripple.svg";
import pulseAnimation from "../../../assets/animations/Pulse.svg";
import productImg from "../../../assets/images/big-sack-logo-abbrev-white-interlay.svg";

export const MainPreloader = () => {
  return (
    <div className="preloader-container">
      <div className="preloader-logo">
        <img src={secondaryLogo} alt="BigSack" />
      </div>
      <img
        className="animation"
        src={rippleAnimation}
        alt="loading"
        loading="lazy"
      />
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <img
      style={{ width: `var(--font-larger)`, height: `var(--font-larger)` }}
      className="animation"
      src={pulseAnimation}
      alt="loading"
      loading="lazy"
    />
  );
};

export const DataLoader = () => {
  return (
    <div className="data-loader">
      <img
        style={{ width: `5rem`, height: `5rem` }}
        className="animation"
        src={rippleAnimation}
        alt="loading"
        loading="lazy"
      />
      <span>Loading your data ....</span>
    </div>
  );
};

//single card pre loader
export const SingleProductCardPreload = () => {
  return (
    <div className="product-card card-preloader">
      <div className="product-link-wraper">
        <img
          src={productImg}
          alt="product"
          className="fallback-product-image"
          style={{ filter: "blur(1.5)" }}
        />
      </div>

      <div className="product-sub">
        <span className="top">
          <span className="name"></span>
          <span className="category"></span>
        </span>
        <span className="bottom">
          <span className="price"></span>
          <span>
            <button
              className="add-to-wishlist"
              title="Add to wishlist"
              style={{ color: `var(--peach-wine)` }}
            ></button>
          </span>
        </span>
      </div>
    </div>
  );
};

//products preloader
export const ProductCardPreload = () => {
  return [...Array(4)].map((prod, index) => (
    <div className="product-card card-preloader" key={index + 1}>
      <div className="product-link-wraper">
        <img
          src={productImg}
          alt="product"
          className="fallback-product-image"
          style={{ filter: "blur(1.5)" }}
        />
      </div>

      <div className="product-sub">
        <span className="top">
          <span className="name"></span>
          <span className="category"></span>
        </span>
        <span className="bottom">
          <span className="price"></span>
          <span>
            <button
              className="add-to-wishlist"
              title="Add to wishlist"
              style={{ color: `var(--peach-wine)` }}
            ></button>
          </span>
        </span>
      </div>
    </div>
  ));
};

//element preloader
export const ElementPreload = ({ className, children }) => {
  return <span className={`${className} card-preloader`}>{children}</span>;
};
