import toast from "react-hot-toast";

//success notification
export const CartItemAdditionSuccessful = () =>
  toast(`Product added to your cart.`, {
    position: "top-right",
    // Custom Icon
    icon: "🧺",

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
  });

//success notification
export const CartItemAdditionFailure = () =>
  toast(
    `We could not add the item to your cart  at this time. Please try again later.`,
    {
      position: "top-right",
      // Custom Icon
      icon: "❌",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
    }
  );

export const CartItemRemovalSuccessful = () =>
  toast(`Product removed from your cart 🧺 successfully .`, {
    position: "top-right",
    // Custom Icon
    icon: "✅",

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
  });

//success notification
export const CartItemRemovalFailure = () =>
  toast(
    `We could not remove the item from your cart at this time. Please try again later.`,
    {
      position: "top-right",
      // Custom Icon
      icon: "❌",

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
    }
  );
